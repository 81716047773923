import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export const JS3QPATH = 'https://player.3qsdn.com/js3q.5.3.20.js';
export const JS3Q_SCRIPT_ID = 'js3q-player';

export interface ScriptOptions {
  anonymous?: boolean;
  async?: boolean;
  defer?: boolean;
  nonce?: string;
  place?: string;
}

const ID_PREFIX = 'yejs_';

@Injectable({
  providedIn: 'root',
})
export class ScriptService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  loadScript(
    id: string,
    path: string,
    options: ScriptOptions = {},
  ): Observable<string> {
    if (this.document.getElementById(id)) return;

    const node = this.document.createElement('script');

    node.id = ID_PREFIX + id;
    node.type = 'text/javascript';
    node.src = path;

    if (options.anonymous) node.crossOrigin = 'anonymous';
    node.async = options.async || false;
    node.defer = options.defer || false;

    const onLoad: Subject<string> = new Subject<string>();
    node.onload = () => {
      onLoad.next(id);
      onLoad.complete();
    };
    node.onerror = err => {
      onLoad.error(err);
      onLoad.complete();
    };

    this.document
      .getElementsByTagName(options.place || 'head')[0]
      .appendChild(node);
    return onLoad.asObservable();
  }

  removeScript(id: string): boolean {
    const node = this.document.getElementById(ID_PREFIX + id);
    if (node) {
      node.parentNode.removeChild(node);
      return true;
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else return false;
  }

  reinsertScripts(scripts: HTMLScriptElement[]) {
    const scriptsInitialLength = scripts.length;
    for (let i = 0; i < scriptsInitialLength; i++) {
      const script = scripts[i];
      const scriptCopy = <HTMLScriptElement>(
        this.document.createElement('script')
      );
      scriptCopy.type = script.type ? script.type : 'text/javascript';
      if (script.innerHTML) {
        scriptCopy.innerHTML = script.innerHTML;
      }
      for (let i = 0; i < script.attributes.length; i++) {
        scriptCopy.setAttribute(
          script.attributes.item(i).nodeName,
          script.attributes.item(i).nodeValue,
        );
      }
      scriptCopy.async = false;
      script.parentNode.replaceChild(scriptCopy, script);
    }
  }
}
